// all side margin
.m-0 {
  margin: 0px !important;
}
.m-2 {
  margin: 2px !important;
}
.m-4 {
  margin: 4px !important;
}
.m-8 {
  margin: 8px !important;
}
.m-12 {
  margin: 12px !important;
}
.m-16 {
  margin: 16px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-24 {
  margin: 24px !important;
}
.m-32 {
  margin: 32px !important;
}

// margin top
.mt-0 {
  margin-top: 0px !important;
}
.ios.mt-i-0 {
  margin-top: 0px !important;
}
.mt-2 {
  margin-top: 2px !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mtn-8 {
  margin-top: -8px;
}
.mt-12 {
  margin-top: 12px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-32 {
  margin-top: 32px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

// margin left
.ml-0 {
  margin-left: 0px !important;
}
.ml-2 {
  margin-left: 2px !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.ml-8 {
  margin-left: 8px !important;
}
.ml-12 {
  margin-left: 12px !important;
}
.ml-16 {
  margin-left: 16px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-24 {
  margin-left: 24px !important;
}
.ml-32 {
  margin-left: 32px !important;
}

// margin right
.mr-0 {
  margin-right: 0px !important;
}
.mr-2 {
  margin-right: 2px !important;
}
.mr-4 {
  margin-right: 4px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.mr-12 {
  margin-right: 12px !important;
}
.mr-16 {
  margin-right: 16px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-24 {
  margin-right: 24px !important;
}
.mr-32 {
  margin-right: 32px !important;
}
.mr-36 {
  margin-right: 36px !important;
}
.mr-40 {
  margin-right: 40px !important;
}



// margin bottom
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-2 {
  margin-bottom: 2px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-36 {
  margin-bottom: 36px !important;
}

