// all side padding
.p-0{
    padding: 0px !important;
}
.p-2{
    padding: 2px !important;
}
.p-4{
    padding: 4px !important;
}
.p-8{
    padding: 8px !important;
}
.p-12{
    padding: 12px !important;
}
.p-16{
    padding: 16px !important;
}
.p-20{
    padding: 20px !important;
}
.p-24{
    padding: 24px !important;
}

// padding top
.pt-0{
    padding-top: 0px !important;
}
.pt-2{
    padding-top: 2px !important;
}
.pt-4{
    padding-top: 4px !important;
}
.pt-8{
    padding-top: 8px !important;
}
.pt-12{
    padding-top: 12px !important;
}
.pt-16{
    padding-top: 16px !important;
}
.pt-20{
    padding-top: 20px !important;
}
.pt-24{
    padding-top: 24px !important;
}
.pt-32{
    padding-top: 32px !important;
}

// padding left
.pl-0{
    padding-left: 0px !important;
}
.pl-2{
    padding-left: 2px !important;
}
.pl-4{
    padding-left: 4px !important;
}
.pl-8{
    padding-left: 8px !important;
}
.pl-12{
    padding-left: 12px !important;
}
.pl-16{
    padding-left: 16px !important;
}
.pl-20{
    padding-left: 20px !important;
}
.pl-24{
    padding-left: 24px !important;
}
.pl-44{
    padding-left: 44px !important;
}
// padding right
.pr-0{
    padding-right: 0px !important;
}
.pr-2{
    padding-right: 2px !important;
}
.pr-4{
    padding-right: 4px !important;
}
.pr-6{
    padding-right: 6px !important;
}
.pr-8{
    padding-right: 8px !important;
}
.pr-12{
    padding-right: 12px !important;
}
.pr-16{
    padding-right: 16px !important;
}
.pr-20{
    padding-right: 20px !important;
}
.pr-24{
    padding-right: 24px !important;
}

// padding bottom
.pb-0{
    padding-bottom: 0px !important;
}
.pb-2{
    padding-bottom: 2px !important;
}
.pb-4{
    padding-bottom: 4px !important;
}
.pb-8{
    padding-bottom: 8px !important;
}
.pb-12{
    padding-bottom: 12px !important;
}
.pb-16{
    padding-bottom: 16px !important;
}
.pb-20{
    padding-bottom: 20px !important;
}
.pb-24{
    padding-bottom: 24px !important;
}