.created-by-lbl{
  color: #119DD1 !important;
  font-size: 12px !important;
  font-family: "Inter-SemiBold" !important;
}
.created-by-lbl-sm{
  color: #119DD1 !important;
  font-size: 10px !important;
  font-family: "Inter-SemiBold" !important;
}
// text css
.lbl-med-wh-6 {
  font-size: 6px !important;
  color: #ffffff !important;
  font-family: "Inter-Medium" !important;
}
.lbl-med-sec-7 {
  font-size: 7px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-med-wh-8 {
  font-size: 8px !important;
  color: #ffffff !important;
  font-family: "Inter-Medium" !important;
}
.lbl-9 {
  font-size: 9px !important;
  color: #000000 !important;
}
.lbl-mp-9 {
  font-size: 9px !important;
  color: #000000 !important;
  font-family: "Inter-Regular" !important;
}
.lbl-med-9 {
  font-size: 9px !important;
  color: #000000 !important;
  font-family: "Inter-Medium" !important;
}
.lbl-bold-9 {
  font-size: 9px !important;
  color: #000000 !important;
  font-family: "Inter-Bold" !important;
}
.lbl-wht-9 {
  font-size: 9px !important;
  color: #ffffff !important;
}
.lbl-op05-9 {
  font-size: 9px !important;
  color: #000000 !important;
  opacity: 0.5;
}
.lbl-mp-op05-9 {
  font-size: 9px !important;
  color: #000000 !important;
  opacity: 0.5;
  font-family: "Inter-Regular" !important;
}
.lbl-sec-9{
  font-size: 9px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Regular" !important;
}
.lbl-10 {
  font-size: 10px !important;
  color: #000000 !important;
}
.lbl-wh-10 {
  font-size: 10px !important;
  color: #ffffff !important;
}
.lbl-med-wh-10 {
  font-size: 10px !important;
  color: #ffffff !important;
  font-family: "Inter-Medium" !important;
}
.lbl-sb-10{
  font-size: 10px !important;
  color: #000000 !important;
  font-family: "Inter-SemiBold" !important;
}
.lbl-sb-wh-10 {
  font-size: 10px !important;
  color: #ffffff !important;
  font-family: "Inter-SemiBold" !important;
}
.lbl-sb-op75-10 {
  font-size: 10px !important;
  color: #000000 !important;
  opacity: 0.75;
  font-family: "Inter-SemiBold" !important;
}
.lbl-med-10 {
  font-size: 10px !important;
  color: #000000 !important;
  font-family: "Inter-Medium" !important;
}
.lbl-sb-sec-10 {
  font-size: 10px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-SemiBold" !important;
}
.lbl-sec-10 {
  font-size: 10px !important;
  // color: #b57709 !important;
  color: var(--ion-color-secondary) !important;
}
.lbl-med-sec-10 {
  font-size: 10px !important;
  font-family: "Inter-Medium" !important;
  color: var(--ion-color-secondary) !important;
}
.lbl-mp-primary-10 {
  font-size: 10px !important;
  color: var(--ion-color-primary) !important;
  font-family: "Inter-Regular" !important;
}
.lbl-lt-op05-10 {
  font-size: 10px !important;
  color: var(--ion-color-light) !important;
  opacity: 0.5;
}
.lbl-op05-10 {
  font-size: 10px !important;
  color: #000000 !important;
  opacity: 0.5;
}
.lbl-med-op05-10 {
  font-size: 10px !important;
  color: #000000 !important;
  opacity: 0.5 !important;
  font-family: "Inter-Medium" !important;
}
.lbl-11 {
  font-size: 11px !important;
  color: #000000 !important;
}
.lbl-med-11 {
  font-size: 11px !important;
  color: #000000 !important;
  font-family: "Inter-Medium" !important;
}
.lbl-med-sec-11 {
  font-size: 11px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-op05-11 {
  font-size: 11px !important;
  color: #000000 !important;
  opacity: 0.5;
}
.lbl-primary-11 {
  font-size: 11px !important;
  color: #bd8014 !important;
}
.lbl-sc-11 {
  font-size: 11px !important;
  color: var(--ion-color-secondary) !important;
}
.lbl-sec-med-11 {
  font-size: 11px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Medium";
}
.lbl-mp-primary-11 {
  font-size: 11px !important;
  color: #bd8014 !important;
  font-family: "Inter-Regular" !important;
}

.lbl-12 {
  font-size: 12px !important;
  color: #000000 !important;
}
.lbl-12-sec {
  font-size: 12px !important;
  color: var(--ion-color-secondary) !important;
}
.lbl-mp-12 {
  font-size: 12px !important;
  color: #000000 !important;
  font-family: "Inter-Regular" !important;
}
.lbl-wh-12{
  font-size: 12px !important;
  color: #ffffff !important;
}
.lbl-med-sec-12 {
  font-size: 12px !important;
  font-family: "Inter-Medium" !important;
  color: var(--ion-color-secondary) !important;
}
.lbl-med-wh-12 {
  font-size: 12px !important;
  color: #ffffff !important;
  font-family: "Inter-Medium" !important;
}
.lbl-mp-12 {
  font-size: 12px !important;
  color: #000000 !important;
}
.lbl-med-12 {
  font-size: 12px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-med-op75-12 {
  font-size: 12px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Medium" !important;
  opacity: 0.75;
}
.lbl-sb-12 {
  font-size: 12px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-SemiBold" !important;
}
.lbl-sb-sec-12 {
  font-size: 12px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-SemiBold" !important;
}
.lbl-sb-wh-12 {
  font-size: 12px !important;
  color: #ffffff !important;
  font-family: "Inter-SemiBold" !important;
}
.lbl-op05-12 {
  font-size: 12px !important;
  color: #000000 !important;
  opacity: 0.5 !important;
}
.lbl-bold-12 {
  font-size: 12px !important;
  color: #000000 !important;
  font-family: "Inter-Bold";
}
.lbl-bold-wh-12{
  font-size: 12px !important;
  color: #FFFFFF !important;
  font-family: "Inter-Bold";
}
.lbl-mp-bold-12 {
  font-size: 12px !important;
  color: #000000 !important;
  font-family: "Inter-Bold" !important;
}
.lbl-sec-12 {
  font-size: 12px !important;
  color: var(--ion-color-secondary) !important;
}
.lbl-sec-sb-12 {
  font-size: 12px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-SemiBold";
}
.lbl-dan-sb-12 {
  font-size: 12px !important;
  color: var(--ion-color-danger) !important;
  font-family: "Inter-SemiBold";
}
.lbl-bold-sec-12 {
  font-size: 12px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Bold" !important;
}
.lbl-13 {
  font-size: 13px !important;
  color: var(--ion-color-dark) !important;
}
.lbl-bold-sec-13 {
  font-size: 13px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Bold" !important;
}
.lbl-sec-13 {
  font-size: 13px !important;
  color: var(--ion-color-secondary) !important;
}
.lbl-mp-13 {
  font-size: 13px !important;
  color: #000000 !important;
  font-family: "Inter-Regular" !important;
}
.lbl-med-13 {
  font-size: 13px !important;
  color: #000000 !important;
  font-family: "Inter-Medium" !important;
}
.lbl-sc-med-13 {
  font-size: 13px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-light-13 {
  font-size: 13px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Light";
}
.lbl-mm-13 {
  font-size: 13px !important;
  color: var(--ion-color-medium) !important;
}
.lbl-sec-sb-13 {
  font-size: 13px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-SemiBold";
}
.lbl-light-13 {
  font-family: "Inter-Light" !important;
  font-size: 13px !important;
  color: #000000 !important;
}
.lbl-op05-13 {
  font-size: 13px !important;
  color: #000000 !important;
  opacity: 0.5 !important;
}
.lbl-bold-dg-13 {
  font-size: 13px !important;
  color: var(--ion-color-danger) !important;
  font-family: 'Inter-Bold';
}
.lbl-dg-13{
  font-size: 13px !important;
  color: var(--ion-color-danger) !important;
}
.lbl-14 {
  font-size: 14px !important;
  color: #000000 !important;
}
.lbl-sb-14{
  font-size: 14px !important;
  color: #000000 !important;
  font-family: 'Inter-SemiBold';
}
.lbl-sb-op75-14{
  font-size: 14px !important;
  color: #000000 !important;
  opacity: 0.75;
  font-family: 'Inter-SemiBold';
}
.lbl-med-wh-14 {
  font-size: 14px !important;
  color: #ffffff !important;
  font-family: "Inter-Medium" !important;
}
.lbl-op05-14 {
  font-size: 14px !important;
  color: #000000 !important;
  opacity: 0.5;
}
.lbl-bold-14 {
  font-size: 14px !important;
  color: #000000 !important;
  font-family: "Inter-Bold" !important;
}
.lbl-med-14 {
  font-size: 14px !important;
  color: #000000 !important;
  font-family: "Inter-Medium" !important;
}
.lbl-dg-14 {
  font-size: 14px !important;
  color: var(--ion-color-danger) !important;
}
.lbl-med-dg-14 {
  font-size: 14px !important;
  color: var(--ion-color-danger) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-primary-14 {
  font-size: 14px !important;
  color: #bd8014 !important;
}
.lbl-primary-bold-14 {
  font-size: 14px !important;
  color: #bd8014 !important;
  font-family: "Inter-Bold" !important;
}
.lbl-sec-14 {
  font-size: 14px !important;
  color: var(--ion-color-secondary) !important;
}
.lbl-sec-bold-14 {
  font-size: 14px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Bold" !important;
}
.lbl-sec-med-14 {
  font-size: 14px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-mp-sec-14 {
  font-size: 14px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Regular" !important;
}

.lbl-danger-14 {
  font-size: 14px !important;
  color: var(--ion-color-danger) !important;
}
.lbl-bold-danger-14 {
  font-size: 14px !important;
  color: var(--ion-color-danger) !important;
  font-family: "Inter-Bold" !important;
}
.lbl-mp-danger-14 {
  font-size: 14px !important;
  color: var(--ion-color-danger) !important;
  font-family: "Inter-Regular" !important;
}
.lbl-ter-14 {
  font-size: 14px !important;
  color: var(--ion-color-tertiary) !important;
}
.lbl-mp-ter-14 {
  font-size: 14px !important;
  color: var(--ion-color-tertiary) !important;
  font-family: "Inter-Regular" !important;
}
.lbl-medium-14 {
  font-size: 14px !important;
  color: var(--ion-color-medium) !important;
}
.lbl-bold-medium-14 {
  font-size: 14px !important;
  color: var(--ion-color-medium) !important;
  font-family: "Inter-Bold" !important;
}
.lbl-mp-medium-14 {
  font-size: 14px !important;
  color: var(--ion-color-medium) !important;
  font-family: "Inter-Regular" !important;
}
.lbl-bold-sec-14 {
  font-size: 14px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Bold" !important;
}
.lbl-op05-14 {
  font-size: 14px !important;
  color: #000000 !important;
  opacity: 0.5 !important;
}
.lbl-15 {
  font-size: 15px !important;
  color: #000000 !important;
}
.lbl-mp-15 {
  font-size: 15px !important;
  color: #000000 !important;
  font-family: "Inter-Regular" !important;
}
.lbl-primary-15 {
  font-size: 15px !important;
  color: #ffa300 !important;
}
.lbl-bold-primary-15 {
  font-size: 15px !important;
  color: #ffa300 !important;
  font-family: "Inter-Bold" !important;
}
.lbl-med-15 {
  font-size: 15px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-med-op05-15 {
  font-size: 15px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Medium" !important;
  opacity: 0.5;
}
.lbl-mp-med-15 {
  font-size: 15px !important;
  color: #000000 !important;
  font-family: "Inter-Medium" !important;
}
.lbl-bold-15 {
  font-size: 15px !important;
  color: #000000 !important;
  font-family: "Inter-Bold" !important;
}
.lbl-mp-bold-15 {
  font-size: 15px !important;
  color: #000000 !important;
  font-family: "Inter-Bold" !important;
}
.lbl-mp-bold-op09-15 {
  font-size: 15px !important;
  color: #000000 !important;
  font-family: "Inter-Bold" !important;
  opacity: 0.9;
}
.lbl-light-15 {
  font-family: "Inter-Light" !important;
  font-size: 15px !important;
  color: #000000 !important;
}
.lbl-thin-15 {
  font-size: 15px;
  color: black !important;
  font-family: "Inter-Thin";
}
.lbl-op05-15 {
  font-size: 15px !important;
  color: #000000 !important;
  opacity: 0.5 !important;
}
.lbl-16 {
  font-size: 16px !important;
  color: var(--ion-color-dark) !important;
}
.lbl-sec-16 {
  font-size: 16px !important;
  color: var(--ion-color-secondary) !important;
}
.lbl-sb-sec-16 {
  font-size: 16px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-SemiBold" !important;
}
.lbl-med-16 {
  font-size: 16px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-med-sec-16 {
  font-size: 16px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-med-wh-16 {
  font-size: 16px !important;
  color: #ffffff !important;
  font-family: "Inter-Medium" !important;
}
.lbl-mp-med-16 {
  font-size: 16px !important;
  color: #000000 !important;
  font-family: "Inter-Medium" !important;
}
.lbl-primary-med-16 {
  font-size: 16px !important;
  color: var(--ion-color-primary) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-op05-16 {
  font-size: 16px !important;
  color: #000000 !important;
  opacity: 0.5 !important;
}
.lbl-med-16 {
  font-size: 16px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Medium";
}
.lbl-bold-16 {
  font-size: 16px !important;
  color: #000000 !important;
  font-family: "Inter-Bold" !important;
}
.lbl-bold-sec-16 {
  font-size: 16px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Bold" !important;
}
.lbl-sb-16 {
  font-size: 16px !important;
  color: #000000 !important;
  font-family: "Inter-SemiBold" !important;
}
.lbl-mp-bold-16 {
  font-size: 16px !important;
  color: #000000 !important;
  font-family: "Inter-bold" !important;
}
.lbl-bold-wh-16 {
  font-size: 16px !important;
  color: #ffffff !important;
  font-family: "Inter-bold" !important;
}
.lbl-med-16 {
  font-size: 16px !important;
  font-family: "Inter-Medium" !important;
}
.lbl-sec-med-16 {
  font-size: 16px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Medium";
}
.lbl-sec-bold-16 {
  font-size: 16px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Bold" !important;
}
.lbl-17 {
  font-size: 17px !important;
  color: #000000 !important;
}
.lbl-med-17 {
  font-size: 17px !important;
  color: #000000 !important;
  font-family: "Inter-Medium";
}
.lbl-bold-17 {
  font-size: 17px !important;
  color: #000000 !important;
  font-family: "Inter-Bold";
}
.lbl-med-18 {
  font-size: 18px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Medium";
}
.lbl-bold-18 {
  font-size: 18px !important;
  color: #000000 !important;
  font-family: "Inter-Bold";
}
.lbl-sb-18 {
  font-size: 18px !important;
  color: #000000 !important;
  font-family: "Inter-SemiBold";
}
.lbl-bold-white-19 {
  font-size: 19px !important;
  color: #ffffff !important;
  font-family: "Inter-Bold";
}
.lbl-sec-bold-18 {
  font-size: 18px !important;
  color: var(--ion-color-secondary) !important;
  font-family: "Inter-Bold";
}
.lbl-20 {
  font-size: 20px !important;
  color: var(--ion-color-dark) !important;
}
.lbl-bold-20 {
  font-size: 20px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Bold";
}

.lbl-med-20 {
  font-size: 20px !important;
  color: #000000 !important;
  font-family: "Inter-Medium";
}
.lbl-bold-21 {
  font-size: 21px !important;
  color: #000000 !important;
  font-family: "Inter-Bold";
}
.lbl-bold-22 {
  font-size: 22px !important;
  color: #000000 !important;
  font-family: "Inter-Bold";
}
.lbl-bold-24 {
  font-size: 24px !important;
  color: #000000 !important;
  font-family: "Inter-Bold";
}
.lbl-bold-25 {
  font-size: 25px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Bold";
}
.lbl-med-26 {
  font-size: 26px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Medium" !important;
}
.lbl-bold-28 {
  font-size: 28px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Bold";
}
.lbl-med-28 {
  font-size: 28px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Medium";
}
.lbl-med-30 {
  font-size: 30px !important;
  color: var(--ion-color-dark) !important;
  font-family: "Inter-Medium";
}
.lbl-35 {
  font-size: 35px !important;
  color: #000000 !important;
}
