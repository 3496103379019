/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Custom css
@import "./assets/styles/custom-margin.scss";
@import "./assets/styles/custom-padding.scss";
@import "./assets/styles/custom-label.scss";
@import "./assets/styles/custom-compo.scss";
@import "./assets/styles/custom-common.scss";
@import "./assets/styles/hover/hover-min.css";

//swiper
@import "~swiper/scss";
@import "~swiper/scss/autoplay";
@import "~swiper/scss/keyboard";
@import "~swiper/scss/pagination";
@import "~swiper/scss/scrollbar";
@import "~swiper/scss/zoom";
@import "~@ionic/angular/css/ionic-swiper";

// @media screen and (min-width: 992px) {
// }
// @media only screen and (max-width: 600px) {

// }

// @media screen and (max-width: 767px) {
//   /* used for iphone portrait */
// }
// @media (min-width: 768px) and (max-width: 990px) {
//   /* used for iphone landscape, ipad/ipad mini portrait */
// }
// @media (min-width: 991px) and (max-width: 1024px) {
//   /* used for ipad mini landscape */
// }
// @media (min-width: 1025px) and (max-width: 1280px) {
//   /* used for ipad landscape */
// }
// @media screen and (min-device-width: 1281px) and (max-device-width: 1600px) {
//   /* Large Desktops */
// }
// @media (min-width: 2000px) {
//   /* 4K ultra HD television */
// }

// custom font
// Inter
@font-face {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Inter-Medium.ttf");
}
@font-face {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-SemiBold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter-Light";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Inter-Light.ttf");
}
ion-tab-bar {
  padding-top: 4px;
  padding-bottom: 4px;
}
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
.ios ion-tab-bar {
  padding-top: 8px;
  padding-bottom: 8px;
}
ion-app {
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
}
ion-select-option {
  ion-label {
    white-space: normal !important;
  }
}
// ion-app {
//   // margin-top: 8px;
//   // margin-bottom: env(safe-area-inset-bottom);
// }
.info-bg {
  --background: #ffebeb;
  border-radius: 8px;
  border-top: 1px solid #ffa4a4;
  border-left: 1.2px solid #ffa4a4;
  border-right: 1.2px solid #ffa4a4;
}
.close-inp {
  border: none;
  background: transparent;
}
.disp-hid {
  display: none;
}
.center-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
pwa-action-sheet {
  .wrapper {
    .content {
      border-radius: 16px !important;
    }
  }
}
ion-footer {
  background: white;
}
.search-cls {
  text-align: left;
  padding-left: 0;
}

// .custom-modal {
//   &::part(backdrop) {
//     //   backdrop-filter: blur(5px) !important;
//     background-color: #0000008c !important;
//     //   opacity: 1.5 !important;
//     --backdrop-opacity: 1.5 !important;
//     //   -webkit-backdrop-filter: blur(5px) !important;
//   }

//   &::part(content) {
//     // bottom: 0 !important;
//     // top: 50% !important;
//     // left: 0 !important;
//     border-radius: 24px !important;
//     background: transparent;
//     box-shadow: none;
//     // height: 50% !important;
//     // height: fit-content !important;
//     height: 90% !important;
//     // margin-bottom: -62% !important;
//     // width: 100% !important;
//   }
// }

.dvr-sec-05 {
  background: #5bccf6;
  opacity: 0.5;
  border: 1px;
  margin: 0px;
  width: 100%;
}
.dvr-med-05 {
  background: #cecece;
  opacity: 0.5;
  border: 1px;
  margin: 0px;
  // width: 100%;
}
.dvr-vl {
  background: #000000;
  opacity: 0.5;
  width: 1px;
  margin: 0px;
  height: 100%;
}

.dvr-1 {
  background: #000000;
  border: 1px;
  margin: 0px;
}

.inner-top-img {
  height: 120px;
}

.ion-item-bg {
  border-top: 1px solid #bebebe;
  border-left: 1.2px solid #bebebe;
  border-right: 1.2px solid #bebebe;
  // border-bottom: 1px solid #5bccF6;
  // border-image-source: linear-gradient(180deg, #5AC1EE 0%, #C6EEFF 100%);
  box-shadow: 0px 2px 6px 0px rgba(240, 240, 240, 1);
  background: #ffffff;
  box-shadow: 0px 2px 6px #f0f0f0;
  border-radius: 8px;
  ion-input {
    @extend .lbl-med-15;
  }
}

.valid-item {
  border-top: 1px solid #5bccf6;
  border-left: 1.2px solid #5bccf6;
  border-right: 1.2px solid #5bccf6;
  // border-bottom: 1px solid #5bccF6;
  // border-image-source: linear-gradient(180deg, #5AC1EE 0%, #C6EEFF 100%);
  box-shadow: 0px 2px 6px 0px rgba(240, 240, 240, 1);
  background: #ffffff;
  box-shadow: 0px 2px 6px #f0f0f0;
  border-radius: 8px;
  ion-input {
    @extend .lbl-med-15;
  }
}
.invalid-item {
  border-top: 1px solid var(--ion-color-danger);
  border-left: 1.2px solid var(--ion-color-danger);
  border-right: 1.2px solid var(--ion-color-danger);
  box-shadow: 0px 2px 6px 0px rgba(240, 240, 240, 1);
  background: #ffffff;
  box-shadow: 0px 2px 6px #f0f0f0;
  border-radius: 8px;
  ion-input {
    @extend .lbl-med-15;
  }
}
.bus-item-sel {
  border-top: 1px solid #5bccf6;
  border-left: 1.2px solid #5bccf6;
  border-right: 1.2px solid #5bccf6;
  // border: 0.8px solid;
  // border-image-source: linear-gradient(180deg, #5AC1EE 0%, rgba(198, 238, 255, 0.31) 100%);
  box-shadow: 0px 3px 8px 0px #eeeeee;
  --background: #f9fdff;
  border-radius: 8px;
}

.bus-item {
  border-top: 1px solid #bebebe;
  border-left: 1.2px solid #bebebe;
  border-right: 1.2px solid #bebebe;
  // border: 0.8px solid;
  // border-image-source: linear-gradient(180deg, #5AC1EE 0%, rgba(198, 238, 255, 0.31) 100%);
  box-shadow: 0px 3px 8px 0px #eeeeee;
  --background: #fbfbfb;
  border-radius: 8px;
}
.error-message {
  color: var(--ion-color-danger);
  font-size: 12px;
}

.btm-content {
  position: absolute;
  bottom: 0;
  width: 100%;
}

//datetimepicker
.mat-datepicker-content {
  border-radius: 16px !important;
}
.mat-calendar-body-selected {
  background-color: var(--ion-color-secondary);
  color: #fff;
}
.mat-calendar-body-cell-content {
  font-size: 16px;
  color: var(--ion-color-dark);
  font-family: "Inter-Regular";
}
.mat-calendar-period-button {
  font-size: 16px;
  color: var(--ion-color-dark);
  font-family: "Inter-Regular";
}
.mat-calendar-table-header th {
  font-size: 14px;
  color: var(--ion-color-dark);
  font-family: "Inter-Regular";
}
.divider1 {
  background: #000000;
  opacity: 0.2;
  border: 1px;
  margin: 0px;
}
.divider2 {
  background: var(--ion-color-secondary);
  opacity: 0.5;
  border: 1px;
  margin: 0px;
}
.divider3 {
  border: 1px;
  margin: 0px;
  background: rgba(255, 255, 255, 0.3);
}
.top-line {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.line-container {
  position: relative;
  text-align: center;
}

.div-line {
  width: 74px;
  height: 6px;
  background: #c4c4c4;
  border-radius: 13px;
  // position: absolute;
  // left: 45%;
  margin-top: 8px;
}
ion-picker {
  --border-radius: 24px 24px 0 0 !important;
}
.picker-opt-selected {
  color: var(--ion-color-secondary) !important;
  font-weight: bold !important;
}
.picker-button {
  color: var(--ion-color-secondary) !important;
}
.search-ui {
  background: #ffffff;
  // background: transparent;
  border: #ffffff;
  padding-right: 12px;
  padding-left: 12px;
  .search-input {
    text-align: left;
    padding-left: 0;
    background: #ffffff;
    border: 0.8px solid #bebebe;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px #f0f0f0;
    border-radius: 9px;
    margin: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;
    --padding-left: 0px;
    height: 44px;
    font-size: 14px;
    color: #878787;
  }
  .sort-div {
    background: #ffffff;
    border: 1px solid rgba(61, 57, 137, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

ion-select::part(icon) {
  content: url("./assets/img/ic_down.svg");
  height: 8px !important;
}
ion-select {
  width: 100%;
}
ion-buttons {
  ion-menu-button {
    ion-icon {
      font-size: 24px;
    }
  }
}

ion-toggle {
  --background-checked: linear-gradient(180deg, #5ac1ee 0%, #c6eeff 100%);
  --background: #eaeaea;
  --handle-background: #ffffff;
  --handle-background-checked: #ffffff;
  height: 24px;
  width: 42px;
  --handle-width: 20px;
  // --handle-height:10px;
}

// ion-checkbox {
//   --background: #f8f8f8;
//   --box-sizing: border-box;
//   --border-radius: 2px;
//   --background-checked: var(--ion-color-secondary);
//   --border-width: 1px;
//   --border-color: #d9d9d9;
//   --border-color-checked: #ffffff;
//   --checkmark-width: 3px;
//   --checkmark-color: #ffffff;
//   height: 22px;
//   width: 22px;
// }
.ui-checkbox {
  --background: #f8f8f8;
  --box-sizing: border-box;
  --border-radius: 2px;
  --background-checked: var(--ion-color-secondary);
  --border-width: 1px;
  --border-color: #d9d9d9;
  --border-color-checked: #ffffff;
  --checkmark-width: 3px;
  --checkmark-color: #ffffff;
  height: 22px;
  width: 22px;
}

ion-tab-button {
  ion-icon {
    @extend .ft-24;
  }
  ion-label {
    text-transform: capitalize;
    margin-top: 4px;
  }
}
.tabbar-ui {
  // ion-tab-bar
  justify-content: start !important;
  width: 100%;
}

.item-0 {
  --inner-padding-end: 0px;
  --inner-padding-start: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  padding-left: 0px;
  padding-right: 0px;
  --min-height: 0px;
}
.bus-details-card {
  background: #ffffff;
  box-shadow: 0px 2px 6px #f0f0f0;
  border-radius: 4px;
  width: 100%;
}
.according-card {
  background: #ffffff;
  box-shadow: 0px 2px 6px #f0f0f0;
  border-radius: 6px;
}
.h-row {
  display: flex;
  flex-direction: row;
  overflow: auto;
  // padding-right: 12px;
}
.hover-bg:hover {
  background: linear-gradient(0deg, #f0d661, #f0d661), #f0d661;
  border-radius: 9px;
  padding-bottom: 8px;
}
.slick-slider {
  width: 100%;
  // margin: auto;
  // background: rgb(14, 13, 13);
}

.slick-88 {
  width: 88% !important;
}
.slick-100 {
  width: 100% !important;
}
.slick-95 {
  width: 95% !important;
}

// body .slick-prev,
// body .slick-next {
//   height: 45px;
//   width: 40px;
//   // background: var(--ion-color-primary) !important;
//   background-image: url('./assets/img/next_pr.svg');
//   z-index: 100;
// }
.slick-dots li button:before {
  color: #030e12 !important;
  opacity: 0.1 !important;
  font-size: 14px !important;
}
.slick-dots li.slick-active button:before {
  color: #fcde67 !important;
  opacity: 1 !important;
  font-size: 14px;
}
.nav-btn {
  height: 48px;
  width: 48px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
}

.prev-slide {
  background: url("./assets/img/prev_pr.svg");
  background-repeat: no-repeat;
  background-size: 48px;
  left: -70px;
}

.next-slide {
  background: url("./assets/img/next_pr.svg");
  background-repeat: no-repeat;
  background-size: 48px;
  right: -56px;
}
ion-radio {
  --color: var(--ion-color-secondary);
  --color-checked: var(--ion-color-secondary);
}

.sel-lang-lbl {
  font-size: 12px;
  color: #767676;
}
.menupage-mt {
  margin-top: 50px;
}
.custom-loading {
  .loading-wrapper {
    padding-top: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;
    border-radius: 48%;
    // padding: 0px;
    // background-color: transparent;
  }
}
.selectable {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.profile-text-height {
  // height: 14px;
}
.grid-px {
  margin-left: 32px;
  margin-right: 32px;
}
.grid-left {
  margin-left: 32px;
}
.tp-title {
  font-style: normal;
  font-family: "Inter-SemiBold";
  font-size: 45px;
  line-height: 65px;
  color: #030e12;
}
.tp-disc {
  font-size: 20px;
  line-height: 30px;
  color: #030e12;
}
.pp-big-mt {
  margin-top: 42px !important;
}
.pp-med-mt {
  margin-top: 24px;
}
.pp-sm-mt {
  margin-top: 18px;
}
.pp-sm-x-mt {
  margin-top: 12px;
}
.pp-subtitle {
  font-size: 26px;
  font-family: "Inter-SemiBold";
  line-height: 32px;
  text-transform: capitalize;
}
.pp-h3-title {
  font-size: 22px;
  font-family: "Inter-SemiBold";
  line-height: 24px;
  text-transform: capitalize;
}
.pp-subdisc {
  font-size: 16px;
  line-height: 24px;
}
.link-text {
  // font-family: 'Inter-SemiBold';
  color: var(--ion-color-secondary);
  cursor: pointer;
}
.list-text {
  margin: 0px;
  padding-left: 16px;
  li {
    font-size: 18px;
    line-height: 26px;
    margin-top: 16px;
  }
}
.num-style {
  list-style-type: decimal;
}
ion-tab-bar {
  border-bottom: white;
}
.name-div {
  background: #fafeff;
  border: 0.5px solid #5bccf6;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 6px 2px 6px;
}
.name-div-inactive {
  background: #fafeff;
  border: 0.5px solid rgba(3, 14, 18, 0.5);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 6px 2px 6px;
}
.container {
  position: relative;
  text-align: center;
}
.top-left {
  position: absolute;
  top: -12px;
  // left: -5px;
  z-index: 1;
}
.template-card {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(153, 151, 151, 0.25);
  border-radius: 9px;
}
.filter-div {
  background: #ffffff;
  border: 1px solid rgba(61, 57, 137, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 8px 12px;
  float: right;
}
.tab-ui {
  ion-segment-button {
    --indicator-color: white;
    --background-checked: white;
    --color-checked: var(--ion-color-secondary);
    --color: #000000;
    // --background: white;
    // --padding-top: 10px;
    --indicator-height: 0px;
    margin: 4px;
    border-radius: 16px;
    ion-label {
      font-size: 0.9em;
    }
  }

  ion-segment {
    --background: #d7f3ff;
    border-radius: 16px;
  }
}
.tab-ui-1 {
  ion-segment-button {
    --indicator-color: #ffffff;
    --background-checked: #ffffff;
    --color-checked: #119dd1;
    --color: #ffffff;
    // --background: white;
    // --padding-top: 10px;
    --indicator-height: 0px;
    margin: 4px;
    border-radius: 16px;
    ion-label {
      p {
        font-size: 0.9em;
      }
    }
  }

  ion-segment {
    // --background: #d7f3ff;
    --background: radial-gradient(114.04% 114.04% at 50% -5.26%, #176080 0%, #033348 100%);
    border-radius: 16px;
  }
}
.amount::before {
  // content: "$";
  content: "\20B9";
}
.lbl-date {
  font-size: 12px;
  color: #acacac !important;
}
.rad-div {
  background: #000000;
  opacity: 0.2;
  border: 1px;
  margin: 0px;
  width: 100%;
}
.bus-view-top-img {
  overflow: hidden;
  height: 240px;
  // width: 100%;
  // object-fit: cover;
}
.bus-view-img-max {
  max-width: 100%;
}
.adv-top-height {
  height: 180px;
  overflow: hidden;
}
.adv-middle-height {
  height: 300px;
  // overflow: hidden;
  // display: flex;
  // align-items: center;
  // height: 100%;
}
.max-adv {
  max-width: 100%;
  max-height: 100%;
}
//media query
@media (min-width: 768px) and (max-width: 990px) {
  .menupage-mt {
    margin-top: 20px;
  }
  .grid-px {
    margin-left: 16px;
    margin-right: 16px;
  }
  .grid-left {
    margin-left: 16px;
  }
  .tp-title {
    font-style: normal;
    font-family: "Inter-SemiBold";
    font-size: 24px;
    line-height: 24px;
    color: #030e12;
  }
  .tp-disc {
    font-size: 18px;
    line-height: 24px;
    color: #030e12;
  }
  .pp-subtitle {
    font-size: 16px;
    font-family: "Inter-SemiBold";
    line-height: 32px;
  }
  .pp-subdisc {
    font-size: 11px;
    line-height: 24px;
  }
  .pp-h3-title {
    font-size: 12px;
    font-family: "Inter-SemiBold";
    line-height: 24px;
  }
  .bus-view-top-img {
    overflow: hidden;
    height: 180px;
  }
}
@media (min-width: 991px) and (max-width: 1024px) {
  /* used for ipad mini landscape */
  .bus-view-top-img {
    overflow: hidden;
    height: 170px;
  }
}
// @media (min-width: 1025px) and (max-width: 1280px) {
//   /* used for ipad landscape */
//   .bus-view-top-img{
//     overflow: hidden;
//     height: 180px;
//   }
// }
// @media only screen and (min-width : 320px) and (max-width : 480px) and (orientation : landscape) {
//   .bus-view-top-img{
//     overflow: hidden;
//     height: 180px;
//   }
// }
@media screen and (max-width: 767px) {
  .menupage-mt {
    margin-top: 20px;
  }
  .grid-px {
    margin-left: 16px;
    margin-right: 16px;
  }
  .grid-left {
    margin-left: 16px;
  }
  .tp-title {
    font-style: normal;
    font-family: "Inter-SemiBold";
    font-size: 22px;
    line-height: 24px;
    color: #030e12;
  }
  .tp-disc {
    font-size: 16px;
    line-height: 24px;
    color: #030e12;
  }
  .pp-subtitle {
    font-size: 14px;
    font-family: "Inter-SemiBold";
    line-height: 24px;
  }
  .pp-subdisc {
    font-size: 12px;
    line-height: 16px;
  }
  .pp-h3-title {
    font-size: 10px;
    font-family: "Inter-SemiBold";
    line-height: 24px;
  }
}
@media (min-width: 1920px) and (max-width: 2560px) {
  .menupage-mt {
    margin-top: 100px;
  }
  .tp-title {
    font-style: normal;
    font-family: "Inter-SemiBold";
    font-size: 55px;
    line-height: 80px;
    color: #030e12;
  }
}
//For header use
@media (min-width: 768px) {
  .mobile-header {
    display: none;
  }

  .header {
    display: flex;
    background: #fff;
    // padding-left: 40px;
    // padding-right: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 768px) {
  .mobile-header {
    // display: block;
    display: flex;
  }

  .header {
    display: none !important;
  }
  .bus-view-img-max {
    max-width: 100%;
    // object-fit: cover;
    // height: 100%;
    // width: 100%;
  }
  .adv-top-height {
    height: 120px;
    overflow: hidden;
  }
  .max-adv {
    max-width: 100%;
    max-height: 100%;
    // object-fit: cover;
    // height: 100%;
    // width: 100%;
  }
  .bus-view-top-img {
    overflow: hidden;
    height: 180px;
  }
}
.lbl-time-opp {
  font-family: "Inter-Medium";
  font-size: 12px !important;
  letter-spacing: 0.01em;
  color: #0e0f0f;
  opacity: 0.5;
}
ion-slides{
  .swiper-wrapper{
      height: auto !important;
  }
 
}
//  ion-slide{
//     width: fit-content !important;
//   }

.sel-services {
  background: #5bccf6;
  border: 0.5px solid #5bccf6;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px #a7e7ff;
  border-radius: 8px;
  color: #ffffff;
  font-family: "Inter-SemiBold";
  font-size: 13px;
  padding: 8px 16px 8px 16px;
}
.services {
  background: #ffffff;
  border: 0.5px solid #5bccf6;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px #f0f0f0;
  border-radius: 9px;
  color: var(--ion-color-secondary);
  font-family: " Inter-SemiBold";
  font-size: 13px;
  padding: 8px 16px 8px 16px;
}