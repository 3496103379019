.btn-1 {
  // font-family: "Inter-Bold";
  // font-size: 16px;
  text-align: center;
  color: var(--ion-color-dark);
  --border-radius: 8px !important;
  // border-top: 1px solid #FFBA01;
  // border-left: 1.2px solid #FFBA01;
  // border-right: 1.2px solid #FFBA01;
  --border-color: #ffba01 #ffba01 #ffba01 #ffba01;
  --border-style: solid solid solid solid;
  --border-width: 1px 1.2px 0px 1.2px;

  //  border-image-source: linear-gradient(180deg, #FFBA01 0%, #F9DF68 100%);
  --background: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
}
.div-btn-1 {
  font-family: "Inter-Bold";
  font-size: 16px;
  text-align: center;
  color: var(--ion-color-dark);
  border-radius: 8px !important;
  border-top: 1px solid #ffba01;
  border-left: 1.2px solid #ffba01;
  border-right: 1.2px solid #ffba01;
  // border-image-source: linear-gradient(180deg, #FFBA01 0%, #F9DF68 100%);
  background: var(--ion-color-primary);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn-2 {
  font-family: "Inter-SemiBold";
  font-size: 12px;
  text-align: center;
  border: 1px solid #fcde67;
  border-radius: 8px;
  --background: #ffffff;
  --background-activated: #ffffff;
  --background-hover: #ffffff;
}
.btn-5 {
  font-family: "Inter-Bold";
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  color: var(--ion-color-dark);
  --border-radius: 8px;
  height: 45px;
  --background: #ffffff;
  --background-activated: #ffffff;
  --border-width: 1px;
}

.btn-3 {
  font-family: "Inter-Bold";
  font-size: 10px;
  text-align: center;
  color: #ffffff;
  text-transform: none;
  border-radius: 8px;
  --background: var(--ion-color-secondary);
  --background-activated:  var(--ion-color-secondary);
}
.btn-4 {
  font-family: "Inter-Medium";
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  height: 36px;
  color: var(--ion-color-dark);
  border-radius: 8px;
  --background: #31cda0;
  --background-activated: #31cda0;
}

.btn-sec {
  color: var(--ion-color-dark);
  border-radius: 8px;
  --background: var(--ion-color-secondary);
  --background-activated: var(--ion-color-secondary);
}

.btn-ps {
  font-family: "Inter-Bold";
  font-size: 10px !important;
  text-align: center;
  color: #ffffff;
  text-transform: none;
  border-radius: 8px;
  height: 40px;
  --background: var(--ion-color-secondary);
  --background-activated:  var(--ion-color-secondary);
  // --background-hover:  var(--ion-color-secondary);
  width: fit-content;
}
.custom-modal-slider {
  &::part(backdrop) {
      // backdrop-filter: blur(5px) !important;
    background-color:transparent !important;
    //   opacity: 1.5 !important;
  }

  &::part(content) {
    border-radius: 16px !important;
    background: transparent;
    box-shadow: none;
    height: 80% !important;
  }
}

/*********************************** Popover, Modal ***********************************/
.custom-popover-4 {
  &::part(content) {
    // border-radius: 8px !important;
    background: linear-gradient(0deg, #ffffff, #ffffff), #ffffff;
    box-shadow: 0px 8px 10px rgba(90, 90, 90, 0.25);
    height: -moz-fit-content !important;
    height: fit-content !important;
    --width: fit-content;
    position: absolute;
  }
  &::part(backdrop) {
    opacity: 0 !important;
    // background: var(--ion-color-dark) !important;
  }
}

@media only screen and (max-width: 768px) {
  .image-toolbar {
    // ion-toolbar {
    // --background: url("../img/toolbar_bg.png") no-repeat 52% center/cover;
    --background: var(--ion-color-primary);
    height: 85px;
    display: flex;
    // margin-bottom: -16px;
    // ion-buttons {
    //   margin-top: -64px;
    // }
    border-bottom-left-radius: 50px;
    ion-title {
      font-family: "Inter-Bold";
      font-size: 18px;
      // width: fit-content;
    }
    // }
  }
  .no-img-toolbar {
    height: 85px;
    display: flex;
    ion-title {
      font-family: "Inter-Bold";
      font-size: 18px;
    }
  }
  .custom-modal-filter {
    &::part(backdrop) {
      background-color: #0000008c !important;
      --backdrop-opacity: 1.5 !important;
    }

    &::part(content) {
      bottom: 0 !important;
      position: absolute;
      border-radius: 24px 24px 0 0 !important;
      background: transparent;
      box-shadow: none;
      height: 60% !important;
    }
  }

  .custom-popover-business {
    &::part(content) {
      bottom: 0 !important;
      top: auto !important;
      left: 0 !important;
      border-radius: 0px 48px 0px 0px !important;
      // border-top-right-radius: 48px !important;
      background: transparent;
      box-shadow: none;
      height: fit-content !important;
      width: 100% !important;
      position: absolute;
    }
    &::part(backdrop) {
      opacity: 0.6 !important;
      background: var(--ion-color-dark) !important;
    }
  }

  .custom-popover {
    &::part(content) {
      bottom: 0 !important;
      top: auto !important;
      left: 0 !important;
      border-radius: 0px 48px 0px 0px !important;
      // border-top-right-radius: 48px !important;
      background: transparent;
      box-shadow: none;
      height: fit-content !important;
      width: 100% !important;
      position: absolute;
    }
    &::part(backdrop) {
      opacity: 0.6 !important;
      background: var(--ion-color-dark) !important;
    }
  }
  .custom-popover-gallery {
    &::part(content) {
      bottom: 0 !important;
      top: auto !important;
      left: 0 !important;
      border-radius: 0px 48px 0px 0px !important;
      // border-top-right-radius: 48px !important;
      background: transparent;
      box-shadow: none;
      height: fit-content !important;
      width: 100% !important;
      position: absolute;
    }
    &::part(backdrop) {
      opacity: 0.6 !important;
      background: var(--ion-color-dark) !important;
    }
  }
  .custom-popover-3 {
    &::part(content) {
      /* bottom: 8% !important; */
      /* top: auto !important; */
      /* left: 25% !important; */
      border-radius: 8px !important;
      // background: transparent;
      box-shadow: none;
      height: -moz-fit-content !important;
      height: fit-content !important;
      // --width:50%;
      --width: fit-content;
      // padding-left: 16px;
      // padding-right: 16px;
      position: absolute;
      //
      background: #ffffff;
      // border: 0.4px solid #000000;
      box-sizing: border-box;
      // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      // border-radius: 9px;
    }
  }
  .custom-modal-success {
    &::part(backdrop) {
      background-color: #0000008c !important;
      --backdrop-opacity: 1.5 !important;
    }

    &::part(content) {
      bottom: 0 !important;
      position: absolute;
      border-radius: 0px 32px 0px 0px !important;
      background: transparent;
      box-shadow: none;
      height: fit-content;
    }
  }

  .datetime-modal {
    &::part(backdrop) {
      background-color: #0000008c !important;
      --backdrop-opacity: 1.5 !important;
    }

    &::part(content) {
      border-radius: 24px !important;
      background: transparent;
      box-shadow: none;
      position: absolute;
      width: 90vw;
      // height: 56vh;
      top: 40%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border: 0;
    }
    ion-datetime {
      border-radius: 24px;
    }
  }
  .custom-modal-slider {
    &::part(backdrop) {
        // backdrop-filter: blur(5px) !important;
      background-color:transparent !important;
      //   opacity: 1.5 !important;
    }
  
    &::part(content) {
      border-radius: 0px !important;
      background: transparent;
      box-shadow: none;
      height: 100% !important;
    }
  }
}

@media only screen and (min-width: 992px) {
  .image-toolbar {
    // ion-toolbar {
    // --background: url("../img/toolbar_bg.png") no-repeat 52% center/cover;
    --background: var(--ion-color-primary);
    height: 85px;
    display: flex;
    // margin-bottom: -16px;
    //   ion-buttons {
    //     // margin-top: -64px;
    //   }
    ion-buttons {
      cursor: pointer;
    }
    border-bottom-left-radius: 0px;
    ion-title {
      font-family: "Inter-Bold";
      font-size: 18px;
      // width: fit-content;
    }
    // }
  }
  .no_img-toolbar {
    height: 85px;
    display: flex;
    ion-buttons {
      cursor: pointer;
    }
    ion-title {
      font-family: "Inter-Bold";
      font-size: 18px;
    }
  }
  .image-toolbar-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    ion-toolbar {
      --background: url("../img/top_bg.png") no-repeat 52% center/cover;
      height: 108px;
      display: flex;
      width: 50%;
    }
  }

  .custom-modal {
    &::part(backdrop) {
      //   backdrop-filter: blur(5px) !important;
      background-color: #0000008c !important;
      //   opacity: 1.5 !important;
      --backdrop-opacity: 1.5 !important;
      //   -webkit-backdrop-filter: blur(5px) !important;
    }

    &::part(content) {
      // bottom: 0 !important;
      // top: 50% !important;
      // left: 0 !important;
      border-radius: 24px !important;
      background: transparent;
      box-shadow: none;
      // height: 50% !important;
      // height: fit-content !important;
      height: 90% !important;
      // margin-bottom: -62% !important;
      // width: 100% !important;
    }
  }
  // filter
  .custom-modal-filter {
    &::part(backdrop) {
      background-color: #0000008c !important;
      --backdrop-opacity: 1.5 !important;
    }

    &::part(content) {
      border-radius: 24px !important;
      background: transparent;
      box-shadow: none;
    }
  }

  .custom-popover-business {
    &::part(content) {
      border-radius: 24px !important;
      background: transparent;
      box-shadow: none;
      // width: auto;
      width: 40%;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      // bottom: 0;
      // height: fit-content;
    }
    &::part(backdrop) {
      opacity: 0.6 !important;
      background: var(--ion-color-dark) !important;
    }
  }

  .custom-popover {
    &::part(content) {
      border-radius: 24px !important;
      background: transparent;
      box-shadow: none;
      // width: auto;
      width: 30%;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      // bottom: 0;
      // height: fit-content;
    }
    &::part(backdrop) {
      opacity: 0.6 !important;
      background: var(--ion-color-dark) !important;
    }
  }
  .pop-width-50{
    &::part(content) {
    width: 50% !important;
    }
  }
  .custom-popover-gallery {
    &::part(content) {
      border-radius: 24px !important;
      background: transparent;
      box-shadow: none;
      // width: auto;
      width: 40%;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      // bottom: 0;
      // height: fit-content;
    }
    &::part(backdrop) {
      opacity: 0.6 !important;
      background: var(--ion-color-dark) !important;
    }
  }
  .custom-popover-3 {
    &::part(content) {
      /* bottom: 8% !important; */
      /* top: auto !important; */
      /* left: 25% !important; */
      border-radius: 8px !important;
      background: #ffffff;
      box-shadow: none;
      height: -moz-fit-content !important;
      height: fit-content !important;
      // --width: 15%;
      --width: fit-content;
      // --width:fit-content;
      // padding-left: 16px;
      // padding-right: 16px;
      position: absolute;
    }
  }

  .datetime-modal {
    &::part(backdrop) {
      background-color: #0000008c !important;
      --backdrop-opacity: 1.5 !important;
    }

    &::part(content) {
      border-radius: 24px !important;
      background: transparent;
      box-shadow: none;
    }
    ion-datetime {
      border-radius: 24px;
    }
  }

  // sucess
  .custom-modal-success {
    &::part(backdrop) {
      background-color: #0000008c !important;
      --backdrop-opacity: 1.5 !important;
    }

    &::part(content) {
      border-radius: 24px !important;
      background: transparent;
      box-shadow: none;
    }
  }
 
}

/*********************************** Toolbar ***********************************/

// .image-toolbar {
//   ion-toolbar {
//     --background: url("../img/toolbar_bg.png") no-repeat 52% center/cover;
//     height: 85px;
//     display: flex;
//     // margin-bottom: -16px;
//     //   ion-buttons {
//     //     // margin-top: -64px;
//     //   }
//     border-bottom-left-radius: 50px;
//     ion-title {
//       font-family: "Inter-Bold";
//       font-size: 18px;
//       // width: fit-content;
//     }
//   }
// }

ion-toolbar {
  padding-left: 16px;
  overflow: hidden;
}
.header-ios ion-toolbar {
  padding-left: 16px !important;
}
.image-toolbar-1 {
  ion-toolbar {
    --background: url("../img/top_bg.png") no-repeat 52% center/cover;
    height: 108px;
    display: flex;
  }
}
.mlines-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.mlines-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.mlines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
