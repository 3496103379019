/*************************** Radius ***************************/
.round-8 {
  border-radius: 8px;
}
.round-10 {
  border-radius: 10px;
}
.round-16 {
  border-radius: 16px;
}
.round-50p {
  border-radius: 50%;
}
/*************************** Avtar  ***************************/

.avt-12 {
  height: 12px;
  width: 12px;
}
.avt-14 {
  height: 14px;
  width: 14px;
}
.avt-18 {
  height: 18px;
  width: 18px;
}
.avt-20 {
  height: 20px;
  width: 20px;
}
.avt-22 {
  height: 22px;
  width: 22px;
}
.avt-24 {
  height: 24px;
  width: 24px;
}
.avt-28 {
  height: 28px;
  width: 28px;
}
.avt-32 {
  height: 32px;
  width: 32px;
}
.avt-36 {
  height: 36px;
  width: 36px;
}
.avt-40 {
  height: 40px;
  width: 40px;
}
.avt-42 {
  height: 42px;
  width: 42px;
}
.avt-48 {
  height: 48px;
  width: 48px;
}
.avt-56 {
  height: 56px;
  width: 56px;
}
.avt-64 {
  height: 64px;
  width: 64px;
}
.avt-70 {
  height: 70px;
  width: 70px;
}
.avt-82 {
  height: 82px;
  width: 82px;
}
.avt-88 {
  height: 88px;
  width: 88px;
}
.avt-100 {
  height: 100px;
  width: 100px;
}
.avt-120 {
  height: 120px;
  width: 120px;
}
/*************************** Height ***************************/
.h-100 {
  height: 100%;
}
.h-80 {
  height: 80%;
}
.h-70 {
  height: 70%;
}
.h-50 {
  height: 50%;
}
.h-p-20 {
  height: 20px;
}
.h-p-40 {
  height: 40px;
}
.h-p-56 {
  height: 56px;
}
.hp-32 {
  height: 32px;
}
.hp-36 {
  height: 36px;
}
.hp-45 {
  height: 45px;
}
.hp-50 {
  height: 50px;
}
.hp-52 {
  height: 52px;
}
.hp-62 {
  height: 62px;
}
.h-p-75 {
  height: 75px;
}
.hp-82 {
  height: 82px;
}
.hp-85 {
  height: 85px;
}
.hp-100 {
  height: 100px;
}
.h-p-135 {
  height: 100px;
}
.item-min-h-0 {
  --min-height: 0px;
}

/*************************** Width  ***************************/

.w-ft {
  width: fit-content;
}
.w-auto {
  width: auto;
}
.w-100 {
  width: 100%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-60 {
  width: 60%;
}
.w-p-70 {
  width: 70px;
}
.wp-64 {
  width: 64px;
}
.wp-70 {
  width: 70px;
}
.wp-120 {
  width: 120px;
}
.wp-154 {
  width: 154px;
}
/*************************** Flex  ***************************/

.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.block {
  display: block;
}
.flex-1 {
  flex: 1;
}

/*************************** Font  ***************************/
.ft-12 {
  font-size: 12px;
}
.ft-16 {
  font-size: 16px;
}

.ft-24 {
  font-size: 24px;
}

.ft-20 {
  font-size: 20px;
}
.ft-22 {
  font-size: 22px;
}
.ft-32 {
  font-size: 32px;
}
.ft-36 {
  font-size: 36px;
}
.ft-48 {
  font-size: 48px;
}

/*************************** Text Weight  ***************************/

.text-bold {
  font-family: "Inter-Bold" !important;
}
.text-semibold {
  font-family: "Inter-SemiBold" !important;
}
.text-medium {
  font-family: "Inter-Medium" !important;
}

/*************************** Font Size  ***************************/

.text-10 {
  font-size: 10px;
}
.text-12 {
  font-size: 12px;
}
.text-14 {
  font-size: 14px;
}
.text-16 {
  font-size: 16px;
}

/*************************** Line Height  ***************************/
.lh-normal {
  line-height: normal;
}
.lh-12 {
  line-height: 12px;
}
.lh-14 {
  line-height: 14px;
}
.lh-15 {
  line-height: 15px;
}
.lh-16 {
  line-height: 16px;
}
.lh-17 {
  line-height: 17px;
}
.lh-18 {
  line-height: 18px;
}
.lh-20 {
  line-height: 20px;
}
.lh-22 {
  line-height: 22px;
}
.lh-24 {
  line-height: 24px;
}
.lh-28 {
  line-height: 28px;
}
.lh-30 {
  line-height: 30px;
}
.lh-32 {
  line-height: 32px;
}
.lh-40 {
  line-height: 40px;
}

/*************************** Latter Spacing  ***************************/

.ls-01 {
  letter-spacing: 0.01em !important;
}
.ls-02 {
  letter-spacing: 0.02em !important;
}
.ls-03 {
  letter-spacing: 0.03em;
}
.ls-05 {
  letter-spacing: 0.05em !important;
}
.ls-06 {
  letter-spacing: 0.06em !important;
}

/*************************** Max Lines  ***************************/

.mlines-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.mlines-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.mlines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

/*************************** Border  ***************************/

.brd-white-2 {
  border: 2px solid #ffffff;
}
.brd-pri-tlr {
  border-top: 1px solid #5bccf6;
  border-left: 1.2px solid #5bccf6;
  border-right: 1.2px solid #5bccf6;
}

/*************************** ion-item  ***************************/

.item-trans {
  --background: transparent;
}
.item-white {
  --background: white;
}

/***************************  Backgrounds  ***************************/

/***************************  cursor  ***************************/

.cursor-ptr {
  cursor: pointer;
}

.max-width-100 {
  max-width: 100%;
}

.txt-mline {
  text-decoration: line-through;
}

/***************************  Positions  ***************************/

.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}
